import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router';

import {
	EvIssueTypesBySeverityBreakdownChart,
	EvSection,
	EvSpinner
} from '@evinced-private/ui-common';

import {
	WFA_SESSION_ISSUE_TYPE_GRAPH_DAID,
	WFA_SESSION_OVERVIEW_SUMMARY_DAID,
	WFA_SESSION_OVERVIEW_TAB_DAID,
	WFA_SESSION_SEVERITIES_GRAPH_DAID,
	WFA_SESSION_TOP_COMPONENTS_GRAPH_DAID
} from 'data-automation-ids/WfaDashboardAutomationIds';
import { useWfaSessionIssueTypesQuery } from 'src/apps/wfa/hooks/useWfaSessionIssueTypesQuery';
import { useWfaSessionSeveritiesQuery } from 'src/apps/wfa/hooks/useWfaSessionSeveritiesQuery';
import { useWfaSessionSummaryQuery } from 'src/apps/wfa/hooks/useWfaSessionSummaryQuery';
import { useWfaTopSessionComponentsQuery } from 'src/apps/wfa/hooks/useWfaSessionTopComponentsQuery';
import { SummaryPane } from 'src/common/components/ev-summary-pane/EvSummaryPane';
import { RoutesHelper } from 'src/common/helpers/RoutesHelper';
import { buildFullTableId } from 'src/common/helpers/TableStateHelper';
import { WFA_SESSION_ISSUES_TABLE_ID } from 'src/common/pages/dashboards-container/dashboard_consts';
import { useTableState } from 'src/common/providers/dashboard/TableStateProvider';
import { SessionViews } from 'src/common/types/WfaSessionViews';

import { ComponentsRaceBarChart } from './charts/race-bar/ComponentsRaceBarChart';
import { BreakdownBySeverityChart } from './charts/severities/BreakdownBySeverityChart';

import './WfaSessionOverview.scss';

interface IWfaSessionOverviewProps {
	sessionId: string;
}

export const WfaSessionOverview: FC<IWfaSessionOverviewProps> = ({ sessionId }) => {
	const history = useHistory();
	const { onFilterChange } = useTableState();

	const issuesTableIdForSession = useMemo(
		() => buildFullTableId(WFA_SESSION_ISSUES_TABLE_ID, sessionId),
		[sessionId]
	);

	const topComponentsQuery = useWfaTopSessionComponentsQuery({
		sessionId
	});
	const severitiesQuery = useWfaSessionSeveritiesQuery({
		sessionId
	});
	const issuesTypesQuery = useWfaSessionIssueTypesQuery({
		sessionId
	});
	const sessionSummaryQuery = useWfaSessionSummaryQuery({
		sessionId
	});

	const sessionComponents = useMemo(() => {
		if (!topComponentsQuery?.data) {
			return [];
		}

		return topComponentsQuery?.data?.map((item) => {
			return {
				componentId: item.componentId,
				percentage: Math.ceil(item.percentage)
			};
		});
	}, [topComponentsQuery?.data]);

	return (
		<EvSection className="wfa-session-overview" dataAutomationId={WFA_SESSION_OVERVIEW_TAB_DAID}>
			{!sessionSummaryQuery.isLoading ? (
				<SummaryPane
					sectionSrLabel="Scan summary"
					className="custom-summary-pane"
					dataAutomationId={WFA_SESSION_OVERVIEW_SUMMARY_DAID}
					items={[
						{
							value: sessionSummaryQuery.data.totalIssues,
							label: 'Total Issues'
						},
						{
							value: sessionSummaryQuery.data.totalComponents,
							label: 'Components'
						},
						{
							value: sessionSummaryQuery.data.totalPages,
							label: 'Pages'
						}
					]}
				/>
			) : (
				<EvSpinner />
			)}
			<EvSection dataAutomationId={WFA_SESSION_ISSUE_TYPE_GRAPH_DAID}>
				{issuesTypesQuery.data && !issuesTypesQuery.isLoading ? (
					<EvIssueTypesBySeverityBreakdownChart
						typesCountBySeverity={issuesTypesQuery.data}
						onBarItemClick={({ type, severity }) => {
							onFilterChange(issuesTableIdForSession, {
								types: [
									{
										value: type,
										label: type
									}
								],
								severities: [
									{
										value: severity,
										label: severity.toString()
									}
								]
							});
							history.push(RoutesHelper.getWfaSessionPath(sessionId, SessionViews.ALL_ISSUES), {
								from: history.location.pathname
							});
						}}
					/>
				) : (
					<EvSpinner />
				)}
			</EvSection>
			<div className="bottom-row-charts">
				<EvSection
					className="severities-chart-section bottom-chart-section"
					dataAutomationId={WFA_SESSION_SEVERITIES_GRAPH_DAID}
					skipLinkId="severities-chart-id"
				>
					{severitiesQuery.data && !severitiesQuery.isLoading ? (
						<BreakdownBySeverityChart
							severitiesCount={severitiesQuery.data}
							onBarItemClick={({ severity }) => {
								onFilterChange(issuesTableIdForSession, {
									severities: [
										{
											value: severity,
											label: severity.toString()
										}
									]
								});
								history.push(RoutesHelper.getWfaSessionPath(sessionId, SessionViews.ALL_ISSUES), {
									from: history.location.pathname
								});
							}}
						/>
					) : (
						<EvSpinner />
					)}
				</EvSection>
				<EvSection
					className="race-bar-chart-section bottom-chart-section"
					dataAutomationId={WFA_SESSION_TOP_COMPONENTS_GRAPH_DAID}
				>
					{topComponentsQuery.data && !topComponentsQuery.isLoading ? (
						<ComponentsRaceBarChart
							sessionComponents={sessionComponents}
							onClick={(componentId) => {
								onFilterChange(issuesTableIdForSession, {
									searchValue: componentId
								});
								history.push(RoutesHelper.getWfaSessionPath(sessionId, SessionViews.ALL_ISSUES), {
									from: history.location.pathname
								});
							}}
						/>
					) : (
						<EvSpinner />
					)}
				</EvSection>
			</div>
		</EvSection>
	);
};
