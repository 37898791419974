import React, { FC } from 'react';

import classNames from 'classnames';

import {
	EvEllipsedTextWithTooltip,
	EvTitle,
	HeadingLevelRange,
	TITLE_MODES
} from '@evinced-private/ui-common';

import { DATA_ITEM_DAID } from 'data-automation-ids/data-automation-ids';

import './DataItem.scss';

type DataItemValue = string | JSX.Element;
interface IDataItemProps {
	label: string;
	value: DataItemValue;
	formatter?: (value: string) => string;
	isMiddleEllipsis?: boolean;
	maxWidth?: number;
	headingLevel?: HeadingLevelRange;
	className?: string;
}

export const DataItem: FC<IDataItemProps> = ({
	label,
	value,
	isMiddleEllipsis,
	formatter,
	maxWidth = 200,
	headingLevel = 3,
	className
}) => {
	const valueFormatter = (stringValue: string): DataItemValue => (
		<EvEllipsedTextWithTooltip
			text={formatter?.(stringValue) ?? stringValue}
			maxWidth={maxWidth}
			isApplyMiddleEllipsis={isMiddleEllipsis}
			showTooltipOnlyOnEllipsed
		/>
	);

	const renderValue = (): JSX.Element => {
		if (typeof value === 'string') {
			return (
				<div className="text-value">{value.trim() ? valueFormatter(value.trim()) : 'N/A'}</div>
			);
		}

		return value;
	};

	return (
		<div
			className={classNames('data-item', className)}
			style={{ maxWidth }}
			data-automation-id={DATA_ITEM_DAID}
		>
			<EvTitle
				titleText={label}
				headingLevel={headingLevel}
				className="label"
				mode={TITLE_MODES.NORMAL}
			/>
			{renderValue()}
		</div>
	);
};
