import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { NewEvincedLogo } from '@evinced-private/ui-common';

import { RoutesHelper } from 'src/common/helpers/RoutesHelper';
import { isLocalhost } from 'src/common/helpers/UrlLocalhostHelper';
import { useUniversalLogin } from 'src/common/hooks/useUniversalLogin';
import { ProductType } from 'src/common/interfaces/Tenant';
import { useBreadcrumbRoutes } from 'src/common/providers/breadcrumbsProvider/BreadcrumbsProvider';
import { useConfiguration } from 'src/common/providers/configurationProvider/ConfigurationProvider';
import { useUserTenant } from 'src/common/providers/userTenantProvider/UserTenantProvider';
import { getMfaDashboardBreadcrumbsRoutes } from 'src/common/services/breadcrumbs/MfaUrlService';
import { getMsdkDashboardBreadcrumbsRoutes } from 'src/common/services/breadcrumbs/MsdkUrlService';
import { getProductDashboardAccessPermission } from 'src/common/services/CommonDashboardService';
import { initSentryUser } from 'src/common/services/SentryService';

import { EvBreadcrumbs, IEvBreadcrumb } from '../ev-breadcrumbs/EvBreadcrumbs';
import { EvHeader } from '../ev-header/EvHeader';

interface IDashboardHeaderProps {
	isAuthenticatedPage: boolean;
}

export const DashboardHeader: FC<IDashboardHeaderProps> = ({
	isAuthenticatedPage
}: IDashboardHeaderProps) => {
	const [shouldShowInviteOption, setShouldShowInviteOption] = useState<boolean>(false);
	const location = useLocation();
	const { isAuthenticated } = useUniversalLogin();
	const { tenant, user } = useUserTenant();
	const { getToggle } = useConfiguration();
	const { breadcrumbRoutes: breadcrumbRoutesFromContext } = useBreadcrumbRoutes();

	useEffect(() => {
		if (isLocalhost) {
			return;
		}

		if (user && tenant) {
			initSentryUser(user, tenant);
		}
	}, [user, tenant]);

	const updateShouldShowInviteOption = useCallback((): void => {
		const isUserAuthenticated = isAuthenticated();
		if (!isAuthenticatedPage || !isUserAuthenticated) {
			setShouldShowInviteOption(false);
			return;
		}
		setShouldShowInviteOption(!!tenant);
	}, [isAuthenticated, isAuthenticatedPage, tenant]);

	useEffect(() => {
		if (shouldShowInviteOption) {
			return;
		}
		updateShouldShowInviteOption();
	}, [location, shouldShowInviteOption, updateShouldShowInviteOption]);

	const getBreadcrumbsRoutes = (): IEvBreadcrumb[] => {
		if (
			location.pathname === RoutesHelper.getMfaSessionReportPathname() &&
			getProductDashboardAccessPermission(tenant, getToggle, ProductType.MOBILE_FLOW_ANALYZER)
		) {
			return getMfaDashboardBreadcrumbsRoutes(location);
		}
		if (
			location.pathname === RoutesHelper.getMobileSdkTestReportPathname() &&
			getProductDashboardAccessPermission(tenant, getToggle, ProductType.MOBILE_SDK)
		) {
			return getMsdkDashboardBreadcrumbsRoutes(location);
		}

		return breadcrumbRoutesFromContext ?? [];
	};

	const breadcrumbRoutes: IEvBreadcrumb[] = getBreadcrumbsRoutes();

	return (
		<EvHeader
			isAuthenticatedPage={isAuthenticated}
			logoIcon={NewEvincedLogo}
			onLogoClickUrl={RoutesHelper.getHomepagePath()}
			className="dashboard-header"
			switchButtonProps={null}
		>
			{breadcrumbRoutes.length > 0 ? <EvBreadcrumbs breadcrumbsRoutes={breadcrumbRoutes} /> : null}
		</EvHeader>
	);
};
