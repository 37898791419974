import React, { FC } from 'react';

import { EvTitle, HeadingLevelRange } from '@evinced-private/ui-common';

import { TEXT_BLOCK_WITH_TITLE_DAID } from 'data-automation-ids/data-automation-ids';

import './TextBlockWithTitle.scss';

interface ITextBlockWithTitleProps {
	name: string;
	value: JSX.Element | string;
	headingLevel?: HeadingLevelRange;
	dataAutomationId?: string;
}

export const TextBlockWithTitle: FC<ITextBlockWithTitleProps> = ({
	name,
	value,
	headingLevel = 2,
	dataAutomationId = TEXT_BLOCK_WITH_TITLE_DAID
}) => {
	if (!value) {
		return null;
	}

	return (
		<div className="text-block-with-title" data-automation-id={dataAutomationId}>
			<EvTitle headingLevel={headingLevel} className="name" titleText={name} />
			<div className="value">{value}</div>
		</div>
	);
};
