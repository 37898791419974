import React from 'react';
import ReactDOM from 'react-dom';

import { AppContainer } from 'src/apps/main/AppContainer';
import { initAnalytics } from 'src/common/services/analytics/AnalyticsService';
import { initHubspotClient } from 'src/common/services/analytics/HubspotService';
import { InitSentry } from 'src/common/services/SentryService';

import './index.css';

// Init analytics
initAnalytics();

// Init hubspot
initHubspotClient();

// Init Sentry
InitSentry();

console.log(`Current UI Version is: ${UI_VERSION}`);

ReactDOM.render(<AppContainer />, document.getElementById('root'));
