import React, { FC } from 'react';

import classNames from 'classnames';

import { EvIcon, EvLinkTypes, FormatHelper } from '@evinced-private/ui-common';

import { BREADCRUMB_ITEM_DAID, BREADCRUMBS_DAID } from 'data-automation-ids/data-automation-ids';
import { EvLinkLocal } from 'src/common/components/ev-link-local/EvLinkLocal';
import BreadCrumbVector from 'src/common/components/icons/BreadCrumbVector.svg';

import './EvBreadcrumbs.scss';

export interface IEvBreadcrumb {
	id: string;
	url: string;
	title: string;
}

interface IEvBreadcrumbProps {
	breadcrumbsRoutes: IEvBreadcrumb[];
	className?: string;
}

const LONG_BREADCRUMB_TITLE_LENGTH = 60;
const SHORT_BREADCRUMB_TITLE_LENGTH = 18;
const BREADCRUMBS_ARIA_LABEL = 'Breadcrumbs';

export const EvBreadcrumbs: FC<IEvBreadcrumbProps> = ({
	breadcrumbsRoutes,
	className
}: IEvBreadcrumbProps): JSX.Element => {
	const classes: string = classNames('ev-breadcrumb', {
		[className]: !!className
	});

	if (!breadcrumbsRoutes?.length) {
		return null;
	}

	return (
		<nav
			aria-label={BREADCRUMBS_ARIA_LABEL}
			className={classes}
			data-automation-id={BREADCRUMBS_DAID}
		>
			<ol className="ev-breadcrumb-list">
				{breadcrumbsRoutes?.map(({ id, url, title }, index: number): JSX.Element => {
					// eslint-disable-next-line no-unsafe-optional-chaining
					const isLastLink: boolean = index === breadcrumbsRoutes?.length - 1;
					const isCurrentPage: boolean = isLastLink;
					// last item should be longer than the rest
					const titleLength = isLastLink
						? LONG_BREADCRUMB_TITLE_LENGTH
						: SHORT_BREADCRUMB_TITLE_LENGTH;
					const linkText: string = title && FormatHelper.cutLongLabel(title, titleLength);

					return (
						<li
							key={`${id}-ev-breadcrumb-item`}
							className="ev-breadcrumb-item"
							data-automation-id={BREADCRUMB_ITEM_DAID}
						>
							<EvLinkLocal
								id={id}
								ariaCurrent={isLastLink ? 'page' : undefined}
								type={EvLinkTypes.NORMAL}
								ariaLabel={title}
								url={url ?? '#'}
								className={isCurrentPage ? 'current-page' : ''}
								linkText={linkText}
							/>
							{!isLastLink && <EvIcon className="breadcrumb-vector" icon={BreadCrumbVector} />}
						</li>
					);
				})}
			</ol>
		</nav>
	);
};
