// copied from semantic with minor changes
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { EvSection, FormatHelper } from '@evinced-private/ui-common';

import {
	SUMMARY_PANE_ITEM_DAID,
	SUMMARY_PANE_ITEM_LABEL_DAID,
	SUMMARY_PANE_ITEM_VALUE_DAID
} from 'data-automation-ids/data-automation-ids';
import { generateDataAutomationIdAttribute } from 'src/common/helpers/DataAutomationIdHelper';
import { generateUUID } from 'src/common/helpers/UUIDHelper';

import './EvSummaryPane.scss';

type SummaryPaneItem = {
	value: string | number;
	label: string;
	link?: string;
};
interface ISummaryPaneProps {
	items: SummaryPaneItem[];
	sectionSrLabel?: string;
	className?: string;
	shouldHaveSkipLink?: boolean;
	dataAutomationId?: string;
}

export const SummaryPane: FC<ISummaryPaneProps> = ({
	items,
	className,
	sectionSrLabel,
	dataAutomationId,
	shouldHaveSkipLink = true
}: ISummaryPaneProps) => {
	const summaryPaneLabelId = generateUUID();
	const renderPaneContent = (value, label): JSX.Element => (
		<>
			<div
				className="value"
				aria-hidden="true"
				{...generateDataAutomationIdAttribute(SUMMARY_PANE_ITEM_VALUE_DAID)}
			>
				{FormatHelper.formatNumber(value)}
			</div>
			<div
				className="label"
				aria-hidden="true"
				{...generateDataAutomationIdAttribute(SUMMARY_PANE_ITEM_LABEL_DAID)}
			>
				{label}
			</div>
		</>
	);

	const renderSummaryItem = ({ label, value, link }: SummaryPaneItem): JSX.Element => {
		return (
			<li
				className="ev-summary-pane-item"
				key={label}
				{...generateDataAutomationIdAttribute(SUMMARY_PANE_ITEM_DAID)}
			>
				<div className="sr-only">{`${value} ${label}`}</div>
				{link ? (
					<Link to={link} aria-label={`${label} details`}>
						{renderPaneContent(value, label)}
					</Link>
				) : (
					renderPaneContent(value, label)
				)}
			</li>
		);
	};
	return (
		<EvSection
			ariaLabelledby={summaryPaneLabelId}
			skipLinkId={shouldHaveSkipLink ? 'summary' : null}
			className="ev-summary-pane-section"
			{...(dataAutomationId ? { dataAutomationId } : {})}
		>
			{sectionSrLabel && (
				<h2 className="sr-only" id={summaryPaneLabelId}>
					{sectionSrLabel}
				</h2>
			)}
			<ul className={`ev-summary-pane ${className || ''}`}>{items.map(renderSummaryItem)}</ul>
		</EvSection>
	);
};
