import React, { FC } from 'react';

import { EvSection, EvTitle } from '@evinced-private/ui-common';

import { DataItem } from 'src/common/components/data-item/DataItem';

import './ReportInfo.scss';

const TEST_INFO_EMPTY_STATE = 'No data to display';
const DATA_ITEM_MAX_WIDTH = 400;

export type IReportInfo = {
	label: string;
	labelFormatter?: (label: string, value: unknown) => string;
	key: string;
	customValueRenderer?: (report) => JSX.Element | null;
	textFormatter?: (value: string) => string;
	isMiddleEllipsis?: boolean;
};

interface IReportInfoProps {
	reportDetails: unknown;
	reportInfo?: IReportInfo[];
	titleText: string;
}

export const ReportInfo: FC<IReportInfoProps> = ({ reportInfo, reportDetails, titleText }) => {
	return (
		<EvSection className="report-info">
			<EvTitle titleText={titleText} headingLevel={2} className="sr-only" />
			{!reportInfo?.length ? (
				<div className="empty-state">{TEST_INFO_EMPTY_STATE}</div>
			) : (
				reportInfo.map((item) => {
					const {
						label,
						key,
						textFormatter,
						isMiddleEllipsis,
						customValueRenderer,
						labelFormatter
					} = item;

					const value = customValueRenderer?.(reportDetails) ?? reportDetails?.[key]?.toString();

					const formattedLabel = labelFormatter?.(label, reportDetails[key]) ?? label;

					if (!value) {
						return null;
					}

					return (
						<DataItem
							key={label}
							label={formattedLabel}
							value={value}
							formatter={textFormatter}
							maxWidth={DATA_ITEM_MAX_WIDTH}
							isMiddleEllipsis={isMiddleEllipsis}
						/>
					);
				})
			)}
		</EvSection>
	);
};
