import React from 'react';

import { TAG_DAID } from 'data-automation-ids/data-automation-ids';

import './Tag.scss';

interface TagProps {
	text: string;
	label: string;
	maxChars?: number;
	tooltipId?: string;
}

export const Tag: React.FC<TagProps> = ({ text, label, tooltipId, maxChars = 40 }) => {
	const showTooltip = text.length > maxChars - 3;
	const trimmedText = showTooltip ? `${text.slice(0, maxChars)}...` : text;

	return (
		<div className="tag-container" data-automation-id={TAG_DAID}>
			<span className="tag-label">{label}:</span>
			<span
				className="tag-text"
				data-tooltip-id={showTooltip ? tooltipId : undefined}
				data-tooltip-content={showTooltip ? text : undefined}
			>
				{trimmedText}
			</span>
		</div>
	);
};
