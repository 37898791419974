import React, { useState } from 'react';

import { BUTTON_TYPES, EvButton } from '@evinced-private/ui-common';

import { WFA_SESSION_ADDITIONAL_INFO_BUTTON_DAID } from 'data-automation-ids/WfaDashboardAutomationIds';
import { IWfaSessionDetails } from 'src/apps/wfa/services/WfaDashboardServiceTypes';

import { PlatformPopup } from '../../../../common/components/platform-popup/PlatformPopup';
import { SessionInfoBlock } from '../session-info-block/SessionInfoBlock';

import './SessionInfoPopup.scss';

interface ISessionInfoPopupProps {
	name: string;
	sessionDetails: IWfaSessionDetails;
	title: string;
}

export const SessionInfoPopup: React.FC<ISessionInfoPopupProps> = ({
	name,
	sessionDetails,
	title
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<div className="session-info-popup">
			<EvButton
				title={title}
				className="view-all-button"
				type={BUTTON_TYPES.LINK}
				onClick={() => setIsModalOpen(true)}
				dataAutomationId={WFA_SESSION_ADDITIONAL_INFO_BUTTON_DAID}
			>
				View All
			</EvButton>
			<PlatformPopup
				popupWidth={600}
				isControlled
				isBgTransparent
				onClose={() => setIsModalOpen(false)}
				title={name}
				isOpen={isModalOpen}
				buttons={[{ title: 'Close', onClick: () => setIsModalOpen(false) }]}
				className="wfa-session-additional-info-popup"
			>
				<SessionInfoBlock sessionDetails={sessionDetails} />
			</PlatformPopup>
		</div>
	);
};
