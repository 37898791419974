import React, { FC } from 'react';

import classNames from 'classnames';

import { EvSection, EvSpinner, EvTitle } from '@evinced-private/ui-common';

import {
	WFA_SESSION_COPY_URL_BUTTON_DAID,
	WFA_SESSION_HEADER_DAID,
	WFA_SESSION_TITLE_DAID
} from 'data-automation-ids/WfaDashboardAutomationIds';
import { useWfaSessionBreadcrumbs } from 'src/apps/wfa/hooks/useWfaSessionBreadcrumbs';
import { useWfaSessionDetailsQuery } from 'src/apps/wfa/hooks/useWfaSessionDetailsQuery';
import { getCurrentWFAFullUrl } from 'src/apps/wfa/services/ShareableLinksService';
import { CopyToClipboard } from 'src/common/components/copy-to-clipboard/CopyToClipboard';
import LinkIcon from 'src/common/components/icons/LinkIcon.svg';
import { ReportInfo } from 'src/common/components/report-info/ReportInfo';

import { getWfaInfoItems } from './WfaSingleSessionHeaderHelper';

import './WfaSingleSessionHeader.scss';

interface IWfaSessionHeaderProps {
	sessionId: string;
	onSessionNotFoundCallback: () => void;
}

export const WfaSingleSessionHeader: FC<IWfaSessionHeaderProps> = ({
	sessionId,
	onSessionNotFoundCallback
}) => {
	const sessionDetailsQuery = useWfaSessionDetailsQuery(sessionId);
	const { name } = sessionDetailsQuery.data ?? {};

	useWfaSessionBreadcrumbs(sessionId, name);

	if (!sessionId || sessionDetailsQuery.isError || (sessionDetailsQuery.data && !name)) {
		onSessionNotFoundCallback();
		return null;
	}

	return sessionDetailsQuery.isLoading ? (
		<EvSpinner />
	) : (
		<div className="wfa-single-session-header" data-automation-id={WFA_SESSION_HEADER_DAID}>
			<div className="title-container">
				<EvTitle
					titleText={name}
					headingLevel={1}
					className="main-title page-heading"
					dataAutomationId={WFA_SESSION_TITLE_DAID}
				/>
				<CopyToClipboard
					renderJustIcon
					renderTooltip
					textToCopy={getCurrentWFAFullUrl(sessionId)}
					className="copy-link-button"
					iconTitle="Copy Session URL"
					icon={LinkIcon}
					dataAutomationId={WFA_SESSION_COPY_URL_BUTTON_DAID}
				/>
			</div>
			<EvSection
				ariaLabel={`Session ${name} info`}
				skipLinkId="session-info-section"
				ariaLive="polite"
				className={classNames('dashboard-section', 'web-sdk-test-info-section')}
			>
				<ReportInfo
					titleText="Session Info"
					reportInfo={getWfaInfoItems()}
					reportDetails={sessionDetailsQuery.data}
				/>
			</EvSection>
		</div>
	);
};
